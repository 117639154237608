import React from 'react';

const About = () => (
  <div>
    <h1>Sobre Mim</h1>
    <p>Sou um desenvolvedor full-stack com experiência em React, Node.js, e SQL.</p>
  </div>
);

export default About;
